import { API, graphqlOperation } from 'aws-amplify';
import Observable from 'zen-observable-ts';

import { removeUserFromQueue as removeUserInQueue, joinSupportQueue as joinQueue } from 'graphql/mutations';
import { getSupportQueueAsAgent } from 'graphql/queries';
import { onJoinSupportQueue, onLeaveSupportQueue } from 'graphql/subscriptions';

import {
  GetSupportQueueAsAgentQuery,
  OnJoinSupportQueueSubscription,
  OnLeaveSupportQueueSubscription,
  RemoveUserFromQueueMutation,
  UserInQueue,
  QueueRemovalReason,
  PublicUserInQueue,
  JoinSupportQueueMutation,
} from 'API';

// TODO: Test if unknown error formatting is for calls made to the AppSync API like it is for calls made with user auth.

/**
 * Get the list of users in the support queue.
 * @returns {Promise<UserInQueue>} The list of users currently in the support queue.
 */
export const getSupportQueue = async (): Promise<UserInQueue[]> => {
  interface GetSupportQueueAsAgentResponse {
    data: GetSupportQueueAsAgentQuery;
  }

  const res = (await API.graphql(graphqlOperation(getSupportQueueAsAgent))) as GetSupportQueueAsAgentResponse;
  return res.data.getSupportQueueAsAgent as UserInQueue[];
};

/**
 * Remove a user from the queue.
 * @param {string} username The Cognito sub value of the user to remove.
 * @returns {Promise<void>} A promise that resolves if the QueuePosition object is successfully removed.
 */
export const removeUserFromQueue = async (username: string): Promise<void> => {
  interface RemoveUserFromQueueResponse {
    data: RemoveUserFromQueueMutation;
  }
  (await API.graphql(graphqlOperation(removeUserInQueue, { userId: username }))) as RemoveUserFromQueueResponse;
};

/**
 * Mark a user in the queue as in a call.
 * @param username The Cognito username value of the user to remove.
 * @returns {Promise<void>} A promise that resolves if the user is successfully marked as in a call.
 */
export const markUserAsInCall = async (username: string): Promise<void> => {
  interface RemoveUserFromQueueResponse {
    data: RemoveUserFromQueueMutation;
  }
  (await API.graphql(
    graphqlOperation(removeUserInQueue, { userId: username, reason: QueueRemovalReason.CALL_STARTED })
  )) as RemoveUserFromQueueResponse;
};

/**
 * Note: Copied from subscribeToLeaveSupportQueue in the mobile folder.
 * Subscribe to receive a notification every time a user exits the queue for any reason.
 * @param next The function to run each time new information is received.
 * @returns The object specifying the subscription.
 */
export const subscribeToLeaveSupportQueue = (next: (currentQueue: PublicUserInQueue[]) => void) => {
  interface LeaveSupportQueueSubscriptionResponse {
    provider: unknown; // TODO: Be more specific about structure of provider if it's ever necessary
    value: {
      data: OnLeaveSupportQueueSubscription;
    };
  }

  return (API.graphql(graphqlOperation(onLeaveSupportQueue)) as Observable<object>).subscribe({
    next: (data: LeaveSupportQueueSubscriptionResponse) => {
      next(data.value.data.onLeaveSupportQueue as PublicUserInQueue[]);
    },
  });
};

/**
 * Subscribe to receive a notification every time a user joins the queue.
 * @param next The function to run each time new information is received.
 * @returns The object specifying the subscription.
 */
export const subscribeToJoinSupportQueue = (next: (res: string) => void) => {
  interface JoinSupportQueueSubscriptionResponse {
    provider: unknown; // TODO: Be more specific about structure of provider if it's ever necessary
    value: {
      data: OnJoinSupportQueueSubscription;
    };
  }

  return (API.graphql(graphqlOperation(onJoinSupportQueue)) as Observable<object>).subscribe({
    next: (data: JoinSupportQueueSubscriptionResponse) => {
      next(data.value.data.onJoinSupportQueue as string);
    },
  });
};
export type CallType = 'appointment' | 'quick';
/**
 * Join the support queue as the currently logged in user.
 * @param callType The type of support call the user is making.
 * @returns {Promise<string>} The random ID of the user in the queue.
 */
export const joinSupportQueue = async (callType: CallType, userId: any): Promise<string> => {
  const request = async () => {
    interface JoinSupportQueueResponse {
      data: JoinSupportQueueMutation;
    }

    const res: JoinSupportQueueResponse = await API.graphql(
      graphqlOperation(joinQueue, { callType: callType.toUpperCase(),username: userId })
    ) as any;
    return res.data.joinSupportQueue as string;
  };
  return makeAppSyncRequestWithErrorFormatting(request);
};

/**
 * Make a request to an AppSync API with help for extracting a deeply nested error message.
 * @param appSyncRequest The request containing a call to an AppSync API
 * @returns The result of the request.
 */
const makeAppSyncRequestWithErrorFormatting = async <Type>(appSyncRequest: () => Type) => {
  try {
    return await appSyncRequest();
  } catch (error) {
    // This assumes that there is only one single error.
    const message = (error as AppSyncError)?.errors[0].message;
    throw message;
  }
};

export interface AppSyncError {
  data: unknown;
  errors: {
    data: unknown;
    errorInfo: unknown;
    errorType: string;
    locations: unknown[];
    message: string;
    path: string[];
  }[];
}