/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const changeUserStatus = /* GraphQL */ `mutation ChangeUserStatus($userId: String!, $isUserApproved: Boolean!) {
  changeUserStatus(userId: $userId, isUserApproved: $isUserApproved)
}
` as GeneratedMutation<
  APITypes.ChangeUserStatusMutationVariables,
  APITypes.ChangeUserStatusMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser($userInfo: CreateUserInfo!) {
  createUser(userInfo: $userInfo)
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser($userId: String!) {
  deleteUser(userId: $userId)
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const joinSupportQueue = /* GraphQL */ `mutation JoinSupportQueue($callType: CallType, $username: String!) {
  joinSupportQueue(callType: $callType, username: $username)
}
` as GeneratedMutation<
  APITypes.JoinSupportQueueMutationVariables,
  APITypes.JoinSupportQueueMutation
>;
export const leaveSupportQueue = /* GraphQL */ `mutation LeaveSupportQueue {
  leaveSupportQueue {
    positionId
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LeaveSupportQueueMutationVariables,
  APITypes.LeaveSupportQueueMutation
>;
export const removeUserFromQueue = /* GraphQL */ `mutation RemoveUserFromQueue($userId: String!, $reason: QueueRemovalReason) {
  removeUserFromQueue(userId: $userId, reason: $reason) {
    positionId
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveUserFromQueueMutationVariables,
  APITypes.RemoveUserFromQueueMutation
>;
export const resendConfirmationEmail = /* GraphQL */ `mutation ResendConfirmationEmail($userId: String!) {
  resendConfirmationEmail(userId: $userId)
}
` as GeneratedMutation<
  APITypes.ResendConfirmationEmailMutationVariables,
  APITypes.ResendConfirmationEmailMutation
>;
export const requestAccountDeletion = /* GraphQL */ `mutation RequestAccountDeletion {
  requestAccountDeletion
}
` as GeneratedMutation<
  APITypes.RequestAccountDeletionMutationVariables,
  APITypes.RequestAccountDeletionMutation
>;
export const sendCallStartedNotification = /* GraphQL */ `mutation SendCallStartedNotification($clientToNotify: ID!) {
  sendCallStartedNotification(clientToNotify: $clientToNotify)
}
` as GeneratedMutation<
  APITypes.SendCallStartedNotificationMutationVariables,
  APITypes.SendCallStartedNotificationMutation
>;
export const setUserPassword = /* GraphQL */ `mutation SetUserPassword(
  $userId: String!
  $password: String!
  $isTemporary: Boolean!
) {
  setUserPassword(
    userId: $userId
    password: $password
    isTemporary: $isTemporary
  )
}
` as GeneratedMutation<
  APITypes.SetUserPasswordMutationVariables,
  APITypes.SetUserPasswordMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($userId: String!, $updatedUserInfo: UpdateUserInfo!) {
  updateUser(userId: $userId, updatedUserInfo: $updatedUserInfo)
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const createUserNote = /* GraphQL */ `mutation CreateUserNote(
  $input: CreateUserNoteInput!
  $condition: ModelUserNoteConditionInput
) {
  createUserNote(input: $input, condition: $condition) {
    clientId
    agentEmail
    agentId
    createdAt
    content
    name
    type
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserNoteMutationVariables,
  APITypes.CreateUserNoteMutation
>;
export const updateUserNote = /* GraphQL */ `mutation UpdateUserNote(
  $input: UpdateUserNoteInput!
  $condition: ModelUserNoteConditionInput
) {
  updateUserNote(input: $input, condition: $condition) {
    clientId
    agentEmail
    agentId
    createdAt
    content
    name
    type
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserNoteMutationVariables,
  APITypes.UpdateUserNoteMutation
>;
export const deleteUserNote = /* GraphQL */ `mutation DeleteUserNote(
  $input: DeleteUserNoteInput!
  $condition: ModelUserNoteConditionInput
) {
  deleteUserNote(input: $input, condition: $condition) {
    clientId
    agentEmail
    agentId
    createdAt
    content
    name
    type
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserNoteMutationVariables,
  APITypes.DeleteUserNoteMutation
>;
export const updateSupportAvailabilityWeekly = /* GraphQL */ `mutation UpdateSupportAvailabilityWeekly(
  $input: UpdateSupportAvailabilityWeeklyInput!
  $condition: ModelSupportAvailabilityWeeklyConditionInput
) {
  updateSupportAvailabilityWeekly(input: $input, condition: $condition) {
    id
    day0 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day1 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day2 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day3 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day4 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day5 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day6 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    isEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSupportAvailabilityWeeklyMutationVariables,
  APITypes.UpdateSupportAvailabilityWeeklyMutation
>;
