/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCallSessionToken = /* GraphQL */ `query GetCallSessionToken {
  getCallSessionToken {
    sessionId
    sessionToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCallSessionTokenQueryVariables,
  APITypes.GetCallSessionTokenQuery
>;
export const getCallSessionTokenAsAgent = /* GraphQL */ `query GetCallSessionTokenAsAgent($clientUserId: ID!) {
  getCallSessionTokenAsAgent(clientUserId: $clientUserId) {
    sessionId
    sessionToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCallSessionTokenAsAgentQueryVariables,
  APITypes.GetCallSessionTokenAsAgentQuery
>;
export const getNumberOfUsers = /* GraphQL */ `query GetNumberOfUsers {
  getNumberOfUsers
}
` as GeneratedQuery<
  APITypes.GetNumberOfUsersQueryVariables,
  APITypes.GetNumberOfUsersQuery
>;
export const getUser = /* GraphQL */ `query GetUser($userId: String!) {
  getUser(userId: $userId) {
    id
    expirationDate
    address {
      street
      city
      state
      zipCode
      __typename
    }
    isApproved
    isConfirmed
    isOAuth
    identities {
      dateCreated
      issuer
      primary
      providerName
      providerType
      userId
      __typename
    }
    reasonForDisable
    email
    givenName
    familyName
    threeDigit
    netsuiteUrl
    phoneNumber
    group
    tags
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const getSupportQueue = /* GraphQL */ `query GetSupportQueue {
  getSupportQueue {
    positionId
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSupportQueueQueryVariables,
  APITypes.GetSupportQueueQuery
>;
export const getSupportQueueAsAgent = /* GraphQL */ `query GetSupportQueueAsAgent {
  getSupportQueueAsAgent {
    username
    agentUsername
    positionId
    zoomSessionId
    givenName
    familyName
    callType
    phoneNumber
    tags
    threeDigit
    status
    joinedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSupportQueueAsAgentQueryVariables,
  APITypes.GetSupportQueueAsAgentQuery
>;
export const listUsers = /* GraphQL */ `query ListUsers($nextToken: String, $filter: String, $groupName: String) {
  listUsers(nextToken: $nextToken, filter: $filter, groupName: $groupName) {
    users {
      id
      expirationDate
      address {
        street
        city
        state
        zipCode
        __typename
      }
      isApproved
      isConfirmed
      isOAuth
      identities {
        dateCreated
        issuer
        primary
        providerName
        providerType
        userId
        __typename
      }
      reasonForDisable
      email
      givenName
      familyName
      threeDigit
      netsuiteUrl
      phoneNumber
      group
      tags
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const isSupportOpen = /* GraphQL */ `query IsSupportOpen {
  isSupportOpen
}
` as GeneratedQuery<
  APITypes.IsSupportOpenQueryVariables,
  APITypes.IsSupportOpenQuery
>;
export const getUserNote = /* GraphQL */ `query GetUserNote($clientId: ID!, $createdAt: AWSDateTime!) {
  getUserNote(clientId: $clientId, createdAt: $createdAt) {
    clientId
    agentEmail
    agentId
    createdAt
    content
    name
    type
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserNoteQueryVariables,
  APITypes.GetUserNoteQuery
>;
export const listUserNotes = /* GraphQL */ `query ListUserNotes(
  $clientId: ID
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelUserNoteFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserNotes(
    clientId: $clientId
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      clientId
      agentEmail
      agentId
      createdAt
      content
      name
      type
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserNotesQueryVariables,
  APITypes.ListUserNotesQuery
>;
export const getSupportAvailabilityWeekly = /* GraphQL */ `query GetSupportAvailabilityWeekly($id: ID!) {
  getSupportAvailabilityWeekly(id: $id) {
    id
    day0 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day1 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day2 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day3 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day4 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day5 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    day6 {
      closeAt
      isEnabled
      openAt
      __typename
    }
    isEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSupportAvailabilityWeeklyQueryVariables,
  APITypes.GetSupportAvailabilityWeeklyQuery
>;
export const listSupportAvailabilityWeeklies = /* GraphQL */ `query ListSupportAvailabilityWeeklies(
  $filter: ModelSupportAvailabilityWeeklyFilterInput
  $limit: Int
  $nextToken: String
) {
  listSupportAvailabilityWeeklies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      day0 {
        closeAt
        isEnabled
        openAt
        __typename
      }
      day1 {
        closeAt
        isEnabled
        openAt
        __typename
      }
      day2 {
        closeAt
        isEnabled
        openAt
        __typename
      }
      day3 {
        closeAt
        isEnabled
        openAt
        __typename
      }
      day4 {
        closeAt
        isEnabled
        openAt
        __typename
      }
      day5 {
        closeAt
        isEnabled
        openAt
        __typename
      }
      day6 {
        closeAt
        isEnabled
        openAt
        __typename
      }
      isEnabled
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSupportAvailabilityWeekliesQueryVariables,
  APITypes.ListSupportAvailabilityWeekliesQuery
>;
